.small-toggle {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.75rem !important;
    line-height: 1 !important;
}

.nav-link {
    opacity: 0.6 !important;
}

.active-menu-item {
    opacity: 1 !important;
    font-weight: 500 !important;
}

.navbar-toggler {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.navbar-toggler:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

@media (min-width: 576px) {
    .responsive-offcanvas .offcanvas {
        width: 50% !important;
    }
}

@media (max-width: 575px) {
    .responsive-offcanvas .offcanvas {
        width: 50% !important;
    }

    .offcanvas-body .nav-link {
        color: var(--bs-nav-link-color) !important;
    }
}