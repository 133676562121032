#dropdown-country::after {
    display: none;
}

#dropdown-country {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

#phoneInputGroup .dropdown-menu {
    max-height: 200px;
    width: 100%;
    overflow: auto;
    padding-top: 0;
}